.App{
  display: flex;
  max-height: 100vh;
  font-family: 'Poppins', sans-serif;
}

.AppBody{
  display: flex;
  flex-direction: column;
  flex: 1;
}

.FlexCon{
  flex: 1;
}

.App input:focus{
  border: 1px #3762ea solid;
}

.Loading{
  height: 100vh;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

textarea{
  font-family: 'Poppins', sans-serif;
}