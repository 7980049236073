.Sidebar{
    height: 100vh;
    width: 250px;
    background-color: #181a1f;
}

.Sidebar h1{
    margin: 0;
    font-size: 23px;
    color: #fff;
    font-weight: 600;
}

.Sidebar h3{
    margin: 0 15px;
    margin-top: 10px;
    color: #97979a;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
}

.SideLogo{
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SidebarMenu{
    display: flex;
    align-items: center;
    margin: 0 8px;
    padding: 7px;
    margin-top: 15px;
    text-decoration: none;
    color: #97979a;
    gap: 10px;
    cursor: pointer;
    user-select: none;
    transition: 0.2s ease;
}

.SidebarMenu:hover{
    color: #fff;
}

.SidebarMenuSelect{
    background-color: #222328;
    color: #fff;
}

.Header{
    height: 70px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    background-color: #fff;
    border-bottom: 1px #dde1ef solid;
}

.Header input{
    padding: 10px 10px;
    font-size: 15px;
    border: #eef0f7 1px solid;
    outline: none;
    border-radius: 3px;
    margin-left: 15px;
}

.Header img{
    height: 30px;
    width: 30px;
    border-radius: 15px;
    object-fit: cover;
}

.Header h3{
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    margin-left: 10px;
    color: #888a99;
}